<template>
	<div style="text-align: center;padding-top:20px;margin-top: -10px;" id="tenderInfo">
		<div style="text-align: left;display:inline-block;padding:10px 50px;width:1150px;">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>招标信息</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<div style="text-align: left;display:inline-block;padding:10px 0;width:1150px;">
			<div style="display: inline-block;width:80%;margin:0px 0 20px 10%;">
				<el-input placeholder="请输入招标项目关键词" v-model="formSearch.name">
					<el-button slot="append" icon="el-icon-search" @click="getTableDate()">搜索</el-button>
				</el-input>
			</div>
			<!--
			<p class="titleInfo">
				<span style="font-weight:550;color:#303133;">公告分类:</span>
				<span style="cursor: pointer;padding: 3px 5px;" @click="noticeChange('')"
					:style="[{color:(formSearch.notice==''?'#fff':'#606266')},{backgroundColor:(formSearch.notice==''?'#409EFF':'#fff')}]"
					class="hoverSpan">全部</span>
				<span v-for="(item,index) in notice" style="cursor: pointer;padding: 3px 5px;" :key="index"
					@click="noticeChange(item.id)"
					:style="[{color:(formSearch.notice==item.id?'#fff':'#606266')},{backgroundColor:(formSearch.notice==item.id?'#409EFF':'#fff')}]"
					class="hoverSpan">
					{{item.name}}
				</span>
			</p>
			
			
				<p class="titleInfo">
					<span style="font-weight:550;color:#303133;">项目分类:</span>
					<span  style="cursor: pointer;padding: 3px 5px;"  @click="typeChange('')" :style="[{color:(formSearch.type==''?'#fff':'#606266')},{backgroundColor:(formSearch.type==''?'#409EFF':'#fff')}]" >
						全部
					</span>
					<span v-for="(item,index) in type" style="cursor: pointer;padding: 3px 5px;" :key="index" @click="typeChange(item.id)" :style="[{color:(formSearch.type==item.id?'#fff':'#606266')},{backgroundColor:(formSearch.type==item.id?'#409EFF':'#fff')}]" >
						{{item.name}}
					</span>
				</p>
				-->
			<el-table :data="tableData" border stripe style="width:1150px;margin:20px 0;"
				:header-cell-style="{color:'#000',fontSize:'16px'}" size="small">
				<el-table-column prop="name" label="项目名称">
					<template slot-scope="scope">
						<p @click="goTo(scope,1)" style="cursor: pointer;" class="titleHover">{{scope.row.name}}</p>
					</template>
				</el-table-column>
				<el-table-column prop="number" label="项目编号" width="140"></el-table-column>
				<el-table-column label="公告类型" width="90">
					<template slot-scope="scope">
						{{ notice[scope.row.notice - 1].name }}
					</template>
				</el-table-column>
				<el-table-column label="招标方式" width="100">
					<template slot-scope="scope">
						{{way[scope.row.way-1].name}}
					</template>
				</el-table-column>
				<el-table-column prop="release_time" label="发布时间" width="140"></el-table-column>
				<el-table-column label="报名截止时间" width="140">
					<template slot-scope="scope">
						<span >{{scope.row.end_time}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="110">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" @click="goTo(scope,1)">查看详情</el-button>
					</template>
				</el-table-column>
			</el-table>
					<!--
				<el-pagination
					style="margin: 20px;"
					background
					@current-change="handleCurrentChange"
					:current-page="formSearch.page"
					:page-sizes="[10]"
					:page-size="formSearch.limit"
					layout="total, prev, pager, next, jumper"
					:total="formSearch.total">
				</el-pagination>
		-->
			<div class="el-pagination is-background" style="margin: 20px;">
				<span class="el-pagination__total">共 {{formSearch.total}} 条</span>
				<ul class="el-pager">
					<li class="num" style="cursor:not-allowed;color: #ccc;">
						&lt;
					</li>
					<li class="num" :class="formSearch.page ==1?'active':''" @click="handleCurrentChange(1)">1</li>
					<li class="num" :class="formSearch.page ==2?'active':''" @click="handleCurrentChange(2)" v-show="formSearch.pages>1">2</li>
					<li class="num" style="cursor:not-allowed;color: #ccc;" v-show="formSearch.pages>2">3</li>
					<li class="num" style="cursor:not-allowed;color: #ccc;" v-show="formSearch.pages>2">4</li>
					<li class="num" style="cursor:not-allowed;color: #ccc;" v-show="formSearch.pages>2">5</li>
					<li class="num" style="cursor:not-allowed;color: #ccc;" v-show="formSearch.pages>2">6</li>
					<li class="num" style="cursor:not-allowed;color: #ccc;" v-show="formSearch.pages>2">…</li>
					<li class="num" style="cursor:not-allowed;color: #ccc;" v-show="formSearch.pages>2" >{{formSearch.pages}}</li>
					<li class="num" style="cursor:not-allowed;color: #ccc;">
						&gt;
					</li>
				</ul>
			</div>
			
		</div>
	</div>
</template>
<script>
	export default {
		name: 'tender',
		data() {
			return {
				screenWidth: 1300,
				formSearch: {
					state: 1,
					notice: '',
					type: '',
					page: 1, //当前页
					limit: 10, //每页的条数
					total: 0, //总条数
					name: '',
					pages:1,
				},
				tableData: [],
			}
		},
		methods: {
			getTableDate: function(res) {
				var that = this;
				if (res != '') {
					that.formSearch.page = 1;
				} //点击查询，恢复第一页
				this.$axios.post(this.baseUrl + 'admin/tender/searchTender', that.formSearch).then(response => {
					if (response.data.code != '200') {
						that.$alert(response.data.msg, '提示', {
							confirmButtonText: '确定'
						});
					} else {
						if(response.data.data.count > 20){
							that.formSearch.total=response.data.data.count+100;
							that.formSearch.pages=Math.ceil(that.formSearch.total * 0.1);
							that.tableData = response.data.data.res
						}else{
							that.formSearch.total=response.data.data.count;
							that.formSearch.pages=Math.ceil(that.formSearch.total * 0.1);
							that.tableData = response.data.data.res
						}
					}
				}).catch(function(error) {
					window.console.log(error)
				}); //ajax请求失败
			},
			noticeChange(val) {
				this.formSearch.notice = val;
				this.formSearch.page = 1;
				this.getTableDate('');
			},
			/*
			typeChange(val) {
				this.formSearch.type=val;
				this.getTableDate('');
			},
			*/
			handleCurrentChange(val) {
				this.formSearch.page = val;
				this.getTableDate('');
			}, //翻页
			goTo(scope,page) {
				console.log(page);
				if(this.formSearch.page > 2){
					this.$alert('查看更多信息,请联系我们注册账号。','提示');
				}else{
					//localStorage.setItem('tenderId',val.row.tender_info_id);
					this.$router.push({
						path: '/tenderInfoContent',
						query: {
							id: window.btoa(window.btoa(scope.row.tender_info_id))
						}
					})
				}
				
			}
		},
		beforeMount: function() {
			if ('val' in this.$route.query) {
				//搜索进入
				this.formSearch.name = this.$route.query.val;
			}
			this.getTableDate();

		},
		mounted() {
			this.bus.$emit('headerImg', [{
				src: require('@/assets/images/bk/招标信息.jpg'),
				href: ""
			}]);
			this.screenWidth = document.documentElement.clientWidth;
			window.addEventListener('resize', () => {
				this.screenWidth = document.documentElement.clientWidth;
			})
	
	}
}
</script>

<style scoped>
	.titleInfo {
		margin: 20px 0;
	}

	.titleInfo span {
		margin-right: 10px;
		color: #909399;
	}

	.hoverSpan:hover {
		background-color: #0084C4;
		color: #fff;
	}

	#tenderInfo /deep/ .el-input__inner {
		border: #C0C4CC 1px solid;
	}

	#tenderInfo /deep/ .el-button {
		border: #C0C4CC 1px solid;
		border-left: none;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.titleHover:hover {
		font-weight: 550;
	}
</style>
